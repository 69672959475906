import { createPinia } from 'pinia'
import { createApp } from 'vue'
import './assets/tailwind.css'

import App from './App.vue'
import router from './router'
import VueGtag, { trackRouter } from "vue-gtag-next";


createApp(App)
  .use(router)
  .use(createPinia())
  .use(VueGtag, {
    property: {
      id: 'G-KZC9JKHJNW'
    }
  })
  .mount('#app')


trackRouter(router, {
  template: (to) => {
    return {
      page_title: to.name,
      page_path: to.path,
    }
  }
})
