import { BeaconWallet } from '@taquito/beacon-wallet';
import { TezosToolkit } from '@taquito/taquito';
import { ComputedRef } from 'vue';
import { defineStore } from 'pinia';
import { useGtag } from 'vue-gtag-next';

const getRandomNode = () => {
  const nodes = [
    'https://mainnet.api.tez.ie',
    'https://mainnet.smartpy.io',
    'https://teznode.letzbake.com',
    'https://rpc.tzbeta.net/',
  ];
  const node = nodes[Math.floor(Math.random() * nodes.length)];

  return node;
}

const wallet = new BeaconWallet({
  name: 'Neonz',
});
const tezos = new TezosToolkit(getRandomNode());
tezos.setProvider({ config: { confirmationPollingTimeoutSecond: 300 * 5 }})

interface IWalletStore {
  userAddress: string|undefined,
  userAddressShort: ComputedRef<string|undefined>,

  loading: boolean,
  hasPermissions: ComputedRef<boolean>,
  desync: () => void,
  requestPermission: () => Promise<void>,

  getWallet: () => BeaconWallet,
  getTezos: () => TezosToolkit,
}


const useWalletStore = defineStore('wallet', {
  state: () => ({
    userAddress: undefined as string|undefined,
    isSyncing: false,
  }),
  getters: {
    hasPermissions(): boolean {
      return this.userAddress !== undefined;
    },
    userAddressShort(): string|undefined {
      return this.userAddress ? `${this.userAddress.substring(0, 5)}...${this.userAddress.slice(-5)}` : undefined
    }
  },
  actions: {
    desync() {
      console.log('Desyncing wallet');
      this.isSyncing = true;
      Promise.all([wallet.disconnect(), wallet.clearActiveAccount()]).then(() => {
        this.userAddress = undefined;
      }).finally(() => {
        this.isSyncing = false;
      });
    },
    sync(): Promise<void> {
      return new Promise((res, rej) => {
        this.isSyncing = true;
        wallet.client.requestPermissions({
          network: {
            // @ts-expect-error; incomplete types
            type: 'mainnet',
          },
        })
        .catch((e) => {
          console.error(e)
          rej(e);
        })
        .then(() => {
          tezos.setWalletProvider(wallet);
          wallet.getPKH().then(pkh => {
            this.userAddress = pkh;
            console.log('BeaconWallet connected', this.hasPermissions);

            // Track login gtag
            const { event } = useGtag();
            event("sync", {
              event_category: 'engagement',
              event_label: 'address',
              value: pkh,
            })
            res();
          })
        }).finally(() => {
          this.isSyncing = false;
        });
      })
    },
  }
})

document.addEventListener('DOMContentLoaded', () => {
  tezos.setWalletProvider(wallet);
  const ws = useWalletStore()
  console.log('Document load')

  wallet.getPKH()
    .catch(() => { console.debug('Wallet not synced yet')})
    .then(pkh => {
      console.log(`pkh: ${pkh}`)
      if (pkh !== undefined) {
        ws.userAddress = pkh
        // Track login gtag
        const { event } = useGtag();
        event("persisted_sync", {
          event_category: 'engagement',
          event_label: 'address',
          value: pkh,
          non_interaction: true,
        })
      }
    });
});

export default useWalletStore;


