import { createRouter, createWebHistory, RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'
import Login from '../views/Login.vue'
import Sync from '../views/Sync.vue'
import useUserStore from '../stores/UserStore';

const Face = () => import('../views/Face.vue');
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Sync',
    component: Sync,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to: RouteLocationNormalizedLoaded, _from: RouteLocationNormalizedLoaded, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
