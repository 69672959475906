import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, Ref } from 'vue';

const useUserStore = defineStore('user', () => {
  const username = useLocalStorage('username', '') as Ref<string>;
  const password = useLocalStorage('password', '') as Ref<string>;

  const isValid = computed(() => {
    if (username.value === 'EJAccess' && password.value === 'qGJYQnB3VIjXnFv0') {
      return true
    }
    return false;
  });

  const setLogin = (usernameInput: string, passwordInput: string) => {
    username.value = usernameInput;
    password.value = passwordInput;
  }

  const reset = () => {
    username.value = '';
    password.value = '';
  }
  return {
    username,
    password,
    isValid,
    reset,
    setLogin,
  }
})

export default useUserStore;

